import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://api.skillkronos.com/mail', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage('Thank you for your interest.'); 
        setEmail(''); 
        console.log('Thank you for your interest.', data);
   
        setEmail(''); 
        props.onHide(); 
      } else {
        console.error('Failed to send email:', response.statusText);
        
      }
    } catch (error) {
      console.error('Error sending email:', error);
  
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ backdropFilter: 'blur(10px)' }}
    >
      <Modal.Header closeButton style={{ backgroundColor: 'black', color: 'white' }}>
        <Modal.Title id="contained-modal-title-vcenter">
          Join The Waitlist
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: 'black', color: 'white' }}>
        {successMessage ? ( 
          <p>{successMessage}</p>
        ) : (
          <>
            <p>
              Thanks for joining the queue! We'll let you know when the app is available for you. Kindly help us with your email address.
            </p>
            <input
              type="email"
              placeholder="Enter your email"
              required
              value={email} 
              onChange={handleEmailChange} 
              className="border w-full border-gray-300 rounded-lg px-4 py-2 mb-4"
              style={{ backgroundColor: 'white', color: '#000' }}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: 'black' }}>
        {!successMessage && ( 
          <Button variant="primary" onClick={handleSubmit}>Send</Button>
        )}
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Header() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div
        className="h-full w-full"
        style={{
          backgroundImage: "url('/1.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex flex-col items-center text-center space-y-6 px-4">
          <img src="skillkronos logo.png 111.png" alt="logo" className="h-[200px] sm:h-[250px] md:h-[300px] " />
          <h1 className="text-[30px] sm:text-[40px] md:text-[50px] text-white font-montserrat font-bold">
            Reclaim your Future
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl font-montserrat text-white">
          Transform your career with our expert  "<b className="text-yellow-300">guidance</b>"
          </p>
          <button 
            className="mt-4 px-4 sm:px-6 py-2 bg-[#316dc6] text-white font-semibold rounded-3xl flex items-center gap-2 hover:bg-[#137ed6] hover:shadow-lg transition duration-200"
            onClick={() => setModalShow(true)} 
          >
            JOIN THE WAITLIST
            <span className="transition-transform duration-200 transform hover:translate-x-1">
              ➔
            </span>
          </button>
        </div>

        {/* Second container */}
        <div className="flex flex-col lg:flex-row justify-between items-center px-4 lg:px-20 space-y-10 lg:space-y-0">
          <div className="lg:w-1/2 p-6 lg:p-20">
            <h1 className="text-2xl sm:text-3xl text-yellow-300 font-montserrat font-bold">
              SkillKronos: Soft Skills for a Strong Career
            </h1>
            <p className="text-white font-montserrat mt-4 lg:mt-10 w-full lg:w-[35rem]">
              Welcome to SkillKronos, your trusted partner in developing essential soft skills for career advancement.
              We understand that in today's competitive job market, technical expertise alone isn't enough.
            </p>
            <p className="text-white font-montserrat mt-4 lg:mt-4 w-full lg:w-[35rem]">
              Our expert-driven approach combines practical insights with actionable strategies, providing you with
              the tools you need to excel in any professional environment.
            </p>
          </div>
          <div data-aos="fade-up" data-aos-duration="3000" className="w-full lg:w-1/2 p-4 lg:p-10">
            <img src="exm.png" alt="example" className="w-full" />
          </div>
        </div>

        {/* Approach Section */}
        <div className="text-yellow-300 font-bold text-xl sm:text-2xl lg:text-3xl ml-4 lg:ml-20 mt-10">
          <h2>Our Professional, Empowering, and Friendly Approach</h2>
        </div>
        <div className="flex flex-col lg:flex-row justify-center items-center px-4 lg:px-20 space-y-8 lg:space-y-0 lg:space-x-8 mt-8">
          <div className="text-center lg:w-1/3">
            <h4 className="text-yellow-300 font-bold text-2xl sm:text-3xl">Professional</h4>
            <p className="text-white font-montserrat mt-4">
              We pride ourselves on delivering high-quality, research-backed content focused on the soft skills
              crucial for career success.
            </p>
          </div>
          <div className="text-center lg:w-1/3">
            <h4 className="text-yellow-300 font-bold text-2xl sm:text-3xl">Empowering</h4>
            <p className="text-white font-montserrat mt-4">
              SkillKronos is committed to inspiring confidence and encouraging career growth through targeted skill
              development.
            </p>
          </div>
          <div className="text-center lg:w-1/3">
            <h4 className="text-yellow-300 font-bold text-2xl sm:text-3xl">Friendly</h4>
            <p className="text-white font-montserrat mt-4">
              Our approachable and supportive demeanor makes learning with SkillKronos feel like having a helpful
              mentor by your side.
            </p>
          </div>
        </div>

        {/* Key Characteristics Section */}
        <div className="text-yellow-300 font-bold text-xl sm:text-2xl lg:text-3xl ml-4 lg:ml-20 mt-10">
          <h2>Key Characteristics of SkillKronos</h2>
        </div>
        <div className="flex flex-col lg:flex-row px-4 lg:px-20 space-y-10 lg:space-y-0 lg:space-x-8 mt-8">
          <div className="lg:w-1/2">
            <img src="image3.png" alt="key characteristic" className="w-[30rem]" />
          </div>
          <div className="lg:w-1/2 space-y-6">
            <div>
              <h2 className="text-yellow-300 font-bold text-2xl">Encouraging and Positive</h2>
              <p className="text-white font-montserrat mt-2">
                We consistently reinforce your potential to succeed by emphasizing the power of developing the right
                skills.
              </p>
            </div>
            <div>
              <h2 className="text-yellow-300 font-bold text-2xl">Clear and Concise</h2>
              <p className="text-white font-montserrat mt-2">
                We believe in making soft skills accessible to everyone by presenting information in a clear,
                easy-to-understand manner.
              </p>
            </div>
            <div>
              <h2 className="text-yellow-300 font-bold text-2xl">Action-Oriented</h2>
              <p className="text-white font-montserrat mt-2">
                SkillKronos provides resources filled with practical exercises, real-world examples, and actionable
                steps for immediate application.
              </p>
            </div>
          </div>
        </div>

        {/* Core Messaging Section */}
        <div className="text-yellow-300 font-bold text-xl sm:text-2xl lg:text-3xl ml-4 lg:ml-10 mt-10">
          <h2>Core Messaging Themes</h2>
        </div>
        <div className="flex flex-col p-10 lg:flex-row justify-center items-start px-4 lg:px-20 mt-8 gap-8 w-full">
          <div className="w-full lg:w-1/4 text-center space-y-4">
            <img className="w-[100px] sm:w-[150px] h-[100px] sm:h-[140px] mx-auto bg-white rounded-full" src="icon1.png" alt="icon 1" />
            <h4 className="text-xl text-yellow-300 font-montserrat font-bold">Career Growth through Soft Skills</h4>
            <p className="text-white font-montserrat mt-2">
              Soft skills are essential for career success, leading to better leadership, improved teamwork, and increased job satisfaction.
            </p>
          </div>

          <div className="w-full lg:w-1/4 text-center space-y-4">
            <img className="w-[100px] sm:w-[150px] h-[100px] sm:h-[140px] mx-auto bg-white rounded-full" src="icon2.png" alt="icon 2" />
            <h4 className="text-xl text-yellow-300 font-montserrat font-bold">Mentorship and
Guidance
</h4>
            <p className="text-white font-montserrat mt-2">
            SkillKronos positions itself as
a trusted mentor, offering
personalized guidance and
support throughout your skill
development journey. We're
here to provide expert advice
and encouragement every
step of the way.
            </p>
          </div>

          <div className="w-full lg:w-1/4 text-center space-y-4">
            <img className="w-[100px] sm:w-[150px] h-[100px] sm:h-[140px] mx-auto bg-white rounded-full" src="icon3.png" alt="icon 3" />
            <h4 className="text-xl text-yellow-300 font-montserrat font-bold">Practical and
            Impactful Learning</h4>
            <p className="text-white font-montserrat mt-2">
            Our focus is on providing
hands-on, applicable
knowledge that translates
directly to workplace results.
We offer real-world scenarios,
case studies, and exercises
that help you put your new
skills into practice
immediately.

            </p>
          </div>

          <div className="w-full lg:w-1/4 text-center space-y-4">
            <img className="w-[100px] sm:w-[150px] h-[100px] sm:h-[140px] mx-auto bg-white rounded-full" src="icon4.png" alt="icon 3" />
            <h4 className="text-xl text-yellow-300 font-montserrat font-bold">Inclusivity and
            Accessibility</h4>
            <p className="text-white font-montserrat mt-2">
            SkillKronos is committed to
creating a supportive learning
environment for professionals
at all skill levels. We believe
everyone deserves the
opportunity to grow and
succeed in their career.

            </p>
          </div>
        </div>

        {/* Modal Component */}
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)} // Close modal
        />
      </div>
    </>
  );
}

export default Header;
