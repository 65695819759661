import Header from './Components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';



import './App.css';

function App() {
  return (
    <Header />
  );
}

export default App;
